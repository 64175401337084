import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import ContactUs from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/MiniCenteredFooter.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

import PortfolioTwoCardsWithImage from "components/cta/GetStartedLight.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <PortfolioTwoCardsWithImage/>
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We know <HighlightedText>Theory.</HighlightedText>
          </>
        }
      />
      {/*<MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />*/}
      <FeatureWithSteps
        subheading1={<Subheading>HOW TO GET STARTED</Subheading>}
        subheading2={<Subheading>HOW TO GET STARTED</Subheading>}
        heading1={
          <>
            For <HighlightedText>Teachers.</HighlightedText>
          </>
        }
        heading2={
          <>
            For <HighlightedText>Students.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Flexible Plans.
          </>
        }
      />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Teachers <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://www.liontheory.com/img/reed.jpg",
            heading: "My students are SO prepared for Student Day",
            quote:
              "Since starting with Lion Theory back in 2019, I've seen my students test scores increase dramatically on both written and aural portions of our Student Day exam.",
            customerName: "Jonathan Reed",
            customerTitle: "Associate Professor, Warner University"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Saves so much time!",
            quote:
              "I have 27 students and spending time grading practice tests is such a pain. With Lion Theory, they can practice at home and I can easily see where they need extra help.",
            customerName: "Robin McAvoy",
            customerTitle: "Owner, Robin's Piano School"
          }
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            Have <HighlightedText>Questions?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What is General Theory?",
            answer: "General Theory is a composite of several theory syllabi including AP Music Theory and materials aligned with MTAC's Certificate of Merit."
          },
          {
            question: "How do I create a studio?",
            answer: "Create a studio by visiting liontheory.com/create-studio"
          },
          {
            question: "How do I add students?",
            answer:
              "Once you have created your teacher account, go to liontheory.com/my-studio and click 'Studio Code' in the top menu. Give this code to your students so that they can join your studio."
          },
          {
            question: "Is there a minimum subscription period?",
            answer:
              "No, there is no minimum subscription period. Students can subscribe for as long as they need. There is no cancellation fee and subscriptions can be cancelled at any time."
          },
          {
            question: "How do I join my teacher's studio?",
            answer:
              "Your teacher should have given you a Studio Code. You can enter that code on signup or enter at any time through your dashboard."
          },
          {
            question: "How do I unsubscribe from Lion Theory?",
            answer:
              "Please visit liontheory.com/unsubscribe in order to unsubscribe."
          }
        ]}
      />
      <ContactUs/>
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
